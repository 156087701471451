import * as React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "../fonts/fonts.css";
import "minireset.css";
import { IdentifierProvider } from "../context/identifier-context";

function Layout({ children, identifier, hideFooter, reverseNavColor }) {
  return (
    <IdentifierProvider identifier={identifier}>
      <Navbar reverseNavColor={reverseNavColor} />
      <main>{children}</main>
      {!hideFooter && <Footer />}
    </IdentifierProvider>
  );
}

export default Layout;
