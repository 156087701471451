import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

const SocialMedia = styled.div`
  display: flex;
  flex-direction: column;
  .copyright {
    font-family: Arial, sans-serif;
    color: #c6c6c6;
    text-transform: uppercase;
    font-size: 0.9375rem;
  }

  .social-icons {
    display: flex;
    align-items: flex-end;
    margin-bottom: 16px;
    > a {
      margin-right: 1rem;
    }
  }

  .soc-email-container {
    display: flex;
    align-items: center;
  }
  .email-to {
    text-decoration: none;
    color: #f37549;
    font-family: "Lora", serif;
    font-style: italic;
    font-size: 0.9375rem;
  }
`;
const FooterStyles = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 5rem;
  > svg.shape {
    position: absolute;
    width: 100%;
    height: 150px;
    left: 0;
    right: 0;
    top: -150px;
    > path {
      width: 100%;
    }
  }
  .created-by {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    p {
      font-family: "Lora", serif;
      font-style: italic;
      font-size: 1.25rem;
      color: #f37549;
    }
  }
  @media screen and (max-width: 1140px) {
    padding: 2rem;
    flex-direction: column;

    align-items: flex-start;
    ${SocialMedia} {
      order: 1;
      .social-icons {
        margin-bottom: 8px;
      }
      .soc-email-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .laan-logo {
      order: -1;
      margin-bottom: 8px;
    }
    .created-by {
      margin-bottom: 1rem;
      p {
        color: #c6c6c6;
        font-size: 1rem;
        font-family: Karla;
        font-style: normal;
        font-weight: 700;
      }
      align-items: flex-start;
      letter-spacing: 0em;
      text-align: left;
      order: 0;
    }
    > svg.shape.desktop {
      display: none;
    }
    .email-to {
      margin-bottom: 6px;
    }
  }
  @media screen and (max-width: 430px) {
    .created-by {
      p {
        font-size: 10px;
      }
    }

    #LOGO {
      margin-top: auto;
      height: auto !important;
      width: 120px;
      margin-bottom: 10px;
    }
  }
`;

function Footer() {
  return (
    <FooterStyles>
      <svg
        className="shape desktop"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 110"
        fill-rule="evenodd"
        clip-rule="evenodd"
        stroke-linejoin="round"
        stroke-miterlimit="2"
      >
        <path
          d="M144 37.326c-56.8-67.601-119.667-28.167-144 0v256.999h1512v-218.5c-164 71.675-594.5 0-738 0-149.213 0-297 33.675-365.5 6.175-45.174-18.136-93.833-21.008-120-6.175-24.5 15.334-87.7 29.1-144.5-38.499Z"
          fill="#fff"
          fill-rule="nonzero"
        />
      </svg>

      <SocialMedia>
        <div className="soc-email-container">
          <div className="social-icons">
            <a
              href="https://www.facebook.com/projectlaan"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                alt="facebook logo"
                src="../images/footer/facebook.svg"
                quality={100}
                width={31}
              />
            </a>
            <a
              href="https://www.instagram.com/projectlaan/"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                alt="ig logo"
                src="../images/footer/instagram.svg"
                quality={100}
                width={32}
              />
            </a>
            <a
              href="https://twitter.com/ProjectLAAN"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                alt="twitter logo"
                src="../images/footer/twitter.svg"
                quality={100}
                width={32}
              />
            </a>
          </div>
          <a className="email-to" href="mailto:projectlaanforhealth@gmail.com">
            projectlaanforhealth@gmail.com
          </a>
        </div>
        <p className="copyright">copyright &copy; project laan 2021</p>
      </SocialMedia>
      <StaticImage
        src="../images/footer/laan-footer.png"
        quality={100}
        className="laan-logo"
        width={136}
        id="LOGO"
      />
      <div className="created-by">
        <p>Designed by Bea Basbas and Janssen Villanueva</p>
        <p>Developed by White Carabao</p>
      </div>
    </FooterStyles>
  );
}

export default Footer;
