import React, { useRef, useEffect, useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import styled, { css } from "styled-components";
import useScrollPast from "../hooks/useScrollPast";
import { useIdentifier } from "../context/identifier-context";
import { gsap } from "gsap";
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock";
const itemScrolled = css`
  opacity: 1;
  transform: translateY(0);
`;

const Nav = styled.nav`
  z-index: 100;
  width: 100%;
  position: fixed;
  background-color: ${(props) => (props.scrolled ? "white" : "transparent")};
  ${(props) =>
    props.reverseNavColor &&
    css`
      background-color: transparent;
    `}
  transition: background-color 0.25s;

  ul {
    display: flex;
    align-items: center;
    padding: 2.5rem 3rem 2.5rem;
    @media screen and (max-width: 550px) {
      padding: 1.5rem 1rem 1rem;
    }

    li {
      text-transform: uppercase;
      font-size: 1.125rem;
      font-family: "Brandon Text", sans-serif;
      color: #f37549;
      margin-left: 48px;
      @media screen and (max-width: 1083px) {
        margin-left: 48px;
      }
      @media screen and (max-width: 561px) {
        margin-left: 0;
      }
      &.desktop {
        @media screen and (max-width: 1100px) {
          display: none;
        }
      }
      &.mobile {
        margin-left: auto;
        > svg {
          transition: all 0.2s ease-in;
          stroke: ${(props) => (props.scrolled ? "#f37549" : "white")};
          ${(props) =>
            props.reverseNavColor &&
            css`
              stroke: #f37549;
            `};
        }
        @media screen and (min-width: 1101px) {
          display: none;
        }
      }
      &.logo {
        margin-left: 0;
        transform: translateY(-20px);
        opacity: ${(props) => (props.reverseNavColor ? 1 : 0)};
        transition: all 0.2s ease-in;
        ${(props) =>
          props.scrolled &&
          typeof props.reverseNavColor === "undefined" &&
          itemScrolled};

        @media screen and (max-width: 550px) {
          transform: scale(0.8);
        }
      }

      &.push-left {
        margin-left: auto;
      }

      &.identifier {
        font-family: "Brandon Grotesque", serif;
        font-size: 40px;
        text-transform: lowercase;
        font-weight: 700;
        font-variant: small-caps;
        margin-left: 4.625rem;
        opacity: 0;
        transform: translateY(-20px);
        transition: all 0.2s ease-in;
        line-height: 20px;
        @media screen and (max-width: 660px) {
          line-height: 30px;
        }

        ${(props) => props.scrolled && itemScrolled};
        @media screen and (max-width: 550px) {
          line-height: 20px;
          margin-left: 0;
          font-size: 1.5rem;
        }
      }

      > a {
        color: ${(props) => (props.scrolled ? "#f37549" : "white")};

        ${(props) =>
          props.reverseNavColor &&
          css`
            color: #f37549;
          `};
      }
    }
  }
`;

const NavLink = styled(Link)`
  position: relative;
  text-decoration: none;
  color: #f37549;

  &.active {
    color: #f37549;
    &::before {
      transform: scaleX(1);
    }
  }

  &:not(.logo)::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #f37549;
    transform: scaleX(0);
    transition: all 0.2s ease-in;
  }
  &:hover {
    &::before {
      transform: scaleX(1);
    }
  }
`;
const MobileNavList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translateY(-100%);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  list-style-type: none;
  background-color: white;
  > svg.close-svg {
    padding: 20px;
  }
  .gatsby-image-wrapper {
    position: relative;
    right: -5%;
    margin-bottom: 2rem;
  }
  .close {
    top: 5%;
    right: 5%;
    width: 60px;
    height: 60px;
    position: absolute;
  }
`;
const MobileNavLink = styled(Link)`
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1.875rem;
  font-family: "Eames Century Modern";
  font-style: italic;
  color: #f37549 !important;
  &.active {
    color: #f37549 !important;
  }
`;

function Navbar({ reverseNavColor }) {
  const navRef = useRef(null);
  const mobileNavRef = useRef();
  const scrolled = useScrollPast(navRef);
  const [mobileNavVisible, setMobileNavVisible] = useState(false);
  const { identifier, setIdentifier } = useIdentifier();

  function toggleMobileNav() {
    setMobileNavVisible((b) => !b);
  }
  useEffect(() => {
    gsap.to(mobileNavRef.current, { y: mobileNavVisible ? 0 : "-100%" });
    if (mobileNavVisible) {
      disableBodyScroll(mobileNavRef.current);
    } else {
      enableBodyScroll(mobileNavRef.current);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [mobileNavVisible]);
  return (
    <Nav ref={navRef} scrolled={scrolled} reverseNavColor={reverseNavColor}>
      <ul>
        <li className="logo">
          <NavLink to="/" activeClassName="active" className="logo">
            <StaticImage src="../images/Layout/logo.png" quality={100} />
          </NavLink>
        </li>
        {identifier ? <li className="identifier ">{identifier}</li> : null}
        <li className="desktop push-left">
          <NavLink to="/" activeClassName="active">
            Home
          </NavLink>
        </li>
        <li className="desktop">
          <NavLink to="/who-we-are" activeClassName="active">
            who we are
          </NavLink>
        </li>
        <li className="desktop">
          <NavLink to="/our-work" activeClassName="active">
            our work
          </NavLink>
        </li>
        <li className="desktop">
          <NavLink to="/projects" activeClassName="active">
            projects
          </NavLink>
        </li>
        <li className="desktop">
          <NavLink to="/contact-us" activeClassName="active">
            contact us
          </NavLink>
        </li>
        <li className="mobile" onClick={toggleMobileNav}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
          >
            <line y1="1" x2="20" y2="1" strokeWidth="2" />
            <line y1="8" x2="20" y2="8" strokeWidth="2" />
            <line y1="15" x2="20" y2="15" strokeWidth="2" />
          </svg>
        </li>
      </ul>
      <MobileNavList className="mobileNav" ref={mobileNavRef}>
        <div className="close" onClick={toggleMobileNav}>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            className="close-svg"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1.5 1L16.5 17" stroke="#F37549" />
            <path d="M17 1.5L0.999999 16.5" stroke="#F37549" />
          </svg>
        </div>
        <StaticImage
          src="../images/Layout/logomob.png"
          width={242}
          quality={100}
          id="mainLogo"
        />

        <li>
          <MobileNavLink to="/">HOME</MobileNavLink>
        </li>
        <li>
          <MobileNavLink to="/who-we-are">Who we are</MobileNavLink>
        </li>
        <li>
          <MobileNavLink to="/our-work">our work</MobileNavLink>
        </li>
        <li>
          <MobileNavLink to="/projects">projects</MobileNavLink>
        </li>
        <li>
          <MobileNavLink to="/contact-us">contact us</MobileNavLink>
        </li>
      </MobileNavList>
    </Nav>
  );
}

export default Navbar;
