import { useState, useEffect } from "react";
import _debounce from "lodash.debounce";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useLocation } from "@reach/router"

export default function useScrollPast(elRef) {
  const [scrolled, setScrolled] = useState(false);
  const [overriden, setOverriden] = useState(false);
  const location = useLocation();

  useEffect(() => {
    function handleScroll() {
      if (elRef.current) {
        if (typeof window !== "undefined") {
          if (window.scrollY > elRef.current.offsetHeight) {
            setScrolled(true);
          } else {
            setScrolled(false);
          }
        }
      }
    }

if (location.pathname === "/our-work" || location.pathname === "/our-work/") {
    ScrollTrigger.create({
      trigger: "#OurWorkStart",
      endTrigger: "#OurWorkEnd",
      onToggle: (self) => {
        setOverriden(self.isActive);
      },
    });
}

    if (typeof window !== "undefined") {
      window.addEventListener("scroll", _debounce(handleScroll, 50));
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", _debounce(handleScroll, 50));
      }
    };
  }, [elRef, location]);
  return !overriden && scrolled;
}
