import * as React from "react";

const IdentifierContext = React.createContext();

function IdentifierProvider({ children, identifier: navIdentifier }) {
  const [identifier, setIdentifier] = React.useState(navIdentifier);
  const value = { identifier, setIdentifier };
  return (
    <IdentifierContext.Provider value={value}>
      {children}
    </IdentifierContext.Provider>
  );
}
function useIdentifier() {
  const context = React.useContext(IdentifierContext);
  if (context === undefined) {
    throw new Error("useIdentifier must be used within an IdentifierProvider");
  }
  return context;
}
export { IdentifierProvider, useIdentifier };
